export const backendHost = 'https://оооптк.рф';

export const initialState = {
    name: "",
    contact_phone: "",
    departure: "",
    arrival: "",
    weight: "",
    description: "",
    transport: ""
}